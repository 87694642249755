import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgencyService } from 'src/app/services/agency/agency.service';
import { CompanyService } from '../services/company/company.service';
import {
  FormDataObject,
  questionOptionsValues,
  questionTypeOptions,
  subjectiveOptions,
  visitTypeOptions,
} from 'src/app/models/form-data';
import { MessageHandlerService } from 'src/app/services/message-handler.service';
import { UserDataManager } from 'src/app/user/UserDataManager';
import { User, UserStatus } from 'src/app/models/user';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  DEFAULT_SEARCH_MIN_CHARACTER,
  rowsPerPageOptions,
  NO_OF_RECORDS_PER_PAGE,
  NO_USER_FOUND,
  ERROR_MESSAGES,
  RoleID,
  SUBJECTIVE_DEFAULT_VALUE, SUBJECTIVE_OTHER_DEFAULT_VALUE, SUBJECTIVE_X11_DEFAULT_VALUE,
} from '../common/Constants';
import { Paginator } from 'primeng/paginator';
import { UtilService } from '../services/util/util.service';
import { LocalStorageService } from '../services/local-storage.service';
import { FormDataService } from 'src/app/services/form-data/form-data.service';
import { HttpParams } from '@angular/common/http';
import { Company } from '../models/company';
import { QuestionTextPipe } from '../common/pipes/question-text.pipe';

@Component({
  selector: 'app-form-data',
  templateUrl: './form-data.component.html',
  styleUrls: ['./form-data.component.scss'],
  providers: [QuestionTextPipe],
})
export class FormDataComponent implements OnInit, OnDestroy {
  currentUser: User;

  // table and pagination variables
  loading: boolean = true;
  pageNo: number = 0;
  noOfRecordsPerRow: number = NO_OF_RECORDS_PER_PAGE;
  rowsPerPageOptions: number[] = rowsPerPageOptions;
  isChangingPage: boolean = false;
  @ViewChild('paginator') paginator: Paginator;
  @ViewChild('dt') table: Table;
  totalFormData: number = 0;
  formDataList: FormDataObject[] = [];
  selectedRowData: FormDataObject = null;

  // search variables
  searchInput: string = '';
  defaultSearchMinCharacter = DEFAULT_SEARCH_MIN_CHARACTER;

  // agency filter
  selectedFilterAgencyIds: string[] = [];
  agenciesFilter: SelectItem[] = [];

  // form variables
  formDataForm: FormGroup;
  sideFormVisible: boolean = false;
  isSubmitting = false;
  isEditMode: boolean = false;
  loadingAgencies: boolean = false;
  companiesAll: Company[] = [];
  companyOptions: SelectItem[] = [];
  filteredCompanyOptions: SelectItem[] = [];
  visitTypeOptions = visitTypeOptions;
  agencies: SelectItem[] = [];

  subscriptions: Subscription[] = [];
  questionOptions = questionTypeOptions;
  subjectiveOptions = subjectiveOptions;
  questionSelectedOption: string = '';
  questionOptionsValues = questionOptionsValues;
  subjectiveDefaultValue = SUBJECTIVE_DEFAULT_VALUE;

  constructor(
    private agencyService: AgencyService,
    private companyService: CompanyService,
    private messageHandlerService: MessageHandlerService,
    private userDataManager: UserDataManager,
    private readonly progressBarService: ProgressBarService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private utilService: UtilService,
    private localStorageService: LocalStorageService,
    private formDataService: FormDataService,
    private questionTextPipe: QuestionTextPipe,
  ) {
    this.matIconRegistry.addSvgIcon(
      'status',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/active-inactive.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/delete-item.svg',
      ),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.userDataManager.user$.subscribe({
        next: (user) => {
          if (user) {
            this.currentUser = user;
            if (
              this.currentUser.is_super_admin ||
              this.currentUser.is_IOH_admin
            ) {
              this.getCompanies();
            }
            this.getFormDataList();
            this.initializeAgencyFilter();
            this.initForm();
          }
        },
        error: (error) => {
          this.messageHandlerService.generateToastMessage(
            'error',
            ERROR_MESSAGES.USER_PROFILE_FETCH,
            error.message || ERROR_MESSAGES.USER_PROFILE_FETCH,
          );
        },
      }),
    );
  }

  prePopulatePerPage() {
    const global = JSON.parse(this.localStorageService.get('globalPageLimit'));
    if (global) {
      this.noOfRecordsPerRow = global.perPage;
    }
  }

  onSelectCompany(event) {
    if (event.value) {
      this.confirmationService.confirm({
        message: ERROR_MESSAGES.CONFIRM_COMPANY_SELECT.MESSAGE,
        header: ERROR_MESSAGES.CONFIRM_COMPANY_SELECT.HEADER,
        icon: 'pi pi-cog',
        acceptLabel: ERROR_MESSAGES.CONFIRM_COMPANY_SELECT.ACCEPT,
        rejectLabel: ERROR_MESSAGES.CONFIRM_COMPANY_SELECT.REJECT,
        accept: () => {
          this.getAgencyListing(event.value);
        },
        reject: () => {
          this.formDataForm.get('companyId').patchValue('');
          this.formDataForm.get('agencies').patchValue([]);
          this.agencies = [];
        },
      });
    }
  }

  removeCompany() {
    this.confirmationService.confirm({
      message: ERROR_MESSAGES.CONFIRM_COMPANY_DELETE.MESSAGE,
      header: ERROR_MESSAGES.CONFIRM_COMPANY_DELETE.HEADER,
      icon: 'pi pi-trash',
      acceptLabel: ERROR_MESSAGES.CONFIRM_COMPANY_DELETE.ACCEPT,
      rejectLabel: ERROR_MESSAGES.CONFIRM_COMPANY_DELETE.REJECT,
      acceptButtonStyleClass: 'btn-delete',
      accept: () => {
        this.formDataForm.get('companyId').patchValue('');
        this.formDataForm.get('agencies').patchValue([]);
        this.agencies = [];
      },
      reject: () => {},
    });
  }

  showRemoveCompany() {
    return !!this.formDataForm?.value?.companyId?.value;
  }

  getCompanies() {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.subscriptions.push(
        this.companyService
          .getAllCompanies({ pagination: { pageNumber: 0, pageSize: 10000 } })
          .subscribe((res) => {
            if (res?.success) {
              this.companiesAll = res.content.data;
              const activeCompanies = res.content.data.filter(
                (company) => company.status == UserStatus.active,
              );
              this.companyOptions = activeCompanies.map((item) => ({
                label: item.name,
                value: item.id,
              }));
            } else if (res) {
              this.messageHandlerService.generateToastMessage(
                'error',
                ERROR_MESSAGES.COMMUNICATION,
                res?.errorMessage,
              );
            }
            this.progressBarService.setProgressBarVisibility(false);
          }),
      );
    } catch (error) {
      this.progressBarService.setProgressBarVisibility(false);
      this.messageHandlerService.generateToastMessage(
        'error',
        ERROR_MESSAGES.COMMUNICATION,
        error,
      );
    }
  }

  filterCompanies(event) {
    const filtered: SelectItem[] = [];
    const query: string = event.query;
    const newUserCompanies: SelectItem[] = this.companyOptions.filter(
      (company) => company.label !== 'All' && company.value !== '',
    );

    for (let i = 0; i < newUserCompanies.length; i++) {
      let checkIfAlreadySelected: boolean = false;
      let company: SelectItem = newUserCompanies[i];
      if (company?.label?.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        const formData: any = this.formDataForm.value;

        if (company?.value && formData?.companyId?.value == company?.value) {
          checkIfAlreadySelected = true;
        }

        if (!checkIfAlreadySelected) {
          filtered.push(company);
        }
      }
    }

    this.filteredCompanyOptions = filtered;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  searchFormData() {
    if (
      this.searchInput &&
      this.searchInput.length >= this.defaultSearchMinCharacter
    ) {
      this.pageNo = 0;
      this.getFormDataList(this.searchInput);
    } else {
      this.getFormDataList();
    }
  }

  onSlideBarClose() {
    this.sideFormVisible = false;
    this.isEditMode = false;
    this.selectedRowData = null;
    this.formDataForm.reset();
    this.isSubmitting = false;
    this.questionSelectedOption = '';
    this.formDataForm
      .get('subjectiveOption')
      .removeValidators(Validators.required);
    // Reset form array
    while (this.formList.length) {
      this.formList.removeAt(0);
    }
    this.addFormListItem(); // Add one empty form list item
    this.agencies = [];
  }

  getCompanySelectedIcon(index) {
    const selectedIndex = this.companiesAll.find((e) => e.id === index?.value);

    return selectedIndex?.logo ?? '';
  }

  onAddFormDataClick() {
    this.isEditMode = false;
    this.sideFormVisible = true;
    if (!(this.currentUser.is_super_admin || this.currentUser.is_IOH_admin)) {
      this.getAgencyListing(this.currentUser.companyId);
    }
  }

  paginate(event) {
    if (!this.isChangingPage) {
      this.utilService.setDataInLocalStorage(
        'globalPageLimit',
        'perPage',
        event.rows,
      );
      this.pageNo = event.page;
      this.noOfRecordsPerRow = event.rows;
      this.getFormDataList();
    }
  }

  changePage() {
    this.isChangingPage = true;
    setTimeout(() => {
      this.paginator.changePage(this.pageNo);
      this.isChangingPage = false;
    });
  }

  getFormDataList(search: string = '') {
    try {
      this.progressBarService.setProgressBarVisibility(true);
      this.loading = true;
      const payload = {
        pagination: {
          page: this.pageNo + 1,
          limit: this.noOfRecordsPerRow,
          search: search,
        },
        agencyIds: this.selectedFilterAgencyIds,
      };

      this.subscriptions.push(
        this.formDataService.getFormDataList(payload).subscribe({
          next: (res) => {
            if (res?.success) {
              // Pre-generate tooltips for each form data item
              this.formDataList = res.content.data.map((item) => ({
                ...item,
                agenciesTooltip: this.generateAgenciesTooltip(item.agencies),
                formListTooltip: this.generateFormListTooltip(item.formList),
              }));
              this.totalFormData = res.content?.pagination?.total;
            } else {
              throw new Error(res?.errorMessage);
            }
            this.loading = false;
            this.progressBarService.setProgressBarVisibility(false);
          },
          error: (error) => this.handleError(error),
        }),
      );
    } catch (error) {
      this.handleError(error);
    }
  }

  // Rename and make private since these are now internal helper methods
  private generateFormListTooltip(
    formList: Array<{ title: string; body: string }>,
  ): string {
    if (!formList?.length) {
      return '';
    }
    try {
      return formList
        .map((form) => `<b>${form.title}</b>: ${form.body}`)
        .join('<hr />');
    } catch (error) {
      console.warn(ERROR_MESSAGES.TOOLTIP_GENERATION_ERROR, error);
      return '';
    }
  }

  get isSuperAdminOrCompanyAdmin() {
    return (
      this.currentUser.is_super_admin ||
      this.currentUser.is_IOH_admin ||
      this.currentUser.is_company_admin
    );
  }

  get userAgencyIds() {
    return (
      this.currentUser.agency_roles?.map((role) => role.agency.agencyID) || []
    );
  }

  userAgencies(agencies: any[]) {
    return agencies.filter((agency) => this.userAgencyIds.includes(agency._id));
  }

  private generateAgenciesTooltip(agencies: any[]): string {
    if (!agencies?.length) {
      return '';
    }
    try {
      let userAgencies = agencies;
      if (!this.isSuperAdminOrCompanyAdmin) {
        userAgencies = this.userAgencies(agencies);
      }
      return userAgencies.map((agency) => `${agency.name}`).join('<hr />');
    } catch (error) {
      console.warn(ERROR_MESSAGES.TOOLTIP_GENERATION_ERROR, error);
      return '';
    }
  }

  getAgencyNames(agencies: any[]): string {
    let userAgencies = agencies;

    if (!this.isSuperAdminOrCompanyAdmin) {
      userAgencies = this.userAgencies(agencies);
    }

    return userAgencies?.map((i) => i.shortName).join(', ') || '';
  }

  sortSelectedValues() {
    if (
      this.selectedFilterAgencyIds &&
      this.selectedFilterAgencyIds.length > 0
    ) {
      this.selectedFilterAgencyIds.sort((a, b) => a.localeCompare(b));
    }
  }

  clearSelectedValues(filterArray: any[]) {
    filterArray.length = 0;
    this.getFormDataList();
  }

  onSelectAgencyOrRole() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
    this.getFormDataList();
  }

  initializeAgencyFilter() {
    try {
      if (
        this.currentUser?.is_company_admin ||
        this.currentUser?.defaultRole === RoleID.IOHAgencyAdmin ||
        this.currentUser?.defaultRole === RoleID.AgencyAdmin
      ) {
        const params = new HttpParams().set(
          'companyId',
          this.currentUser.companyId,
        );
        this.loadAgencies(params, true);
      } else if (
        this.currentUser?.is_super_admin ||
        this.currentUser?.is_IOH_admin
      ) {
        this.loadAgencies(null, true);
      }
    } catch (error) {
      this.handleError(error, ERROR_MESSAGES.INITIALIZE_AGENCY_FILTER);
    }
  }

  private loadAgencies(params?: HttpParams, initialLoad: boolean = false) {
    this.loadingAgencies = true;
    this.agencies = [];

    if (
      initialLoad &&
      (this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin)
    ) {
      params = null;
    }

    return this.agencyService.getAgencies(params).subscribe({
      next: (response) => {
        if (response.success) {
          let agencyList = response.content.data.map((agency) => ({
            label: agency.name,
            value: agency.id,
          }));

          if (
            this.currentUser?.defaultRole === RoleID.IOHAgencyAdmin ||
            this.currentUser?.defaultRole === RoleID.AgencyAdmin
          ) {
            // Filter agencies based on user's agency_roles
            const userAgencyIds =
              this.currentUser.agency_roles?.map(
                (role) => role.agency.agencyID,
              ) || [];

            agencyList = agencyList.filter((agency) =>
              userAgencyIds.includes(agency.value),
            );
          }

          this.agencies = agencyList;
          if (initialLoad) {
            this.agenciesFilter = agencyList;
          }
        } else {
          throw new Error(
            response.errorMessage || ERROR_MESSAGES.FETCH_AGENCIES,
          );
        }
      },
      error: (error) => this.handleError(error, ERROR_MESSAGES.FETCH_AGENCIES),
      complete: () => {
        this.loadingAgencies = false;
      },
    });
  }

  initForm() {
    const formGroup = {
      agencies: [[], Validators.required],
      visitTypes: ['', Validators.required],
      question: ['', Validators.required],
      formList: this.formBuilder.array([]),
      questionOption: [''],
      subjectiveOption: [''],
    };

    // Only add company field for super admin and IOH admin
    if (this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin) {
      formGroup['companyId'] = ['', Validators.required];
    }

    this.formDataForm = this.formBuilder.group(formGroup);
    this.addFormListItem();
  }

  // Getter for easy access to formList as FormArray
  get formList() {
    return this.formDataForm.get('formList') as FormArray;
  }

  // Add new form list item
  addFormListItem() {
    const formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      body: ['', Validators.required],
    });
    this.formList.push(formGroup);
  }

  // Remove form list item
  removeFormListItem(index: number) {
    this.formList.removeAt(index);
  }

  private transformOriginalQuestionValue(value: string = '') {
    return value?.trim().replace(/\s+/g, '__');
  }

  // Handle form submission
  onSubmit() {
    let companyId = this.formDataForm.value.companyId?.value;
    if (!(this.currentUser.is_super_admin || this.currentUser.is_IOH_admin)) {
      companyId = this.currentUser.companyId;
    }

    if (this.formDataForm.valid) {
      this.isSubmitting = true;
      const formData = {
        ...this.formDataForm.value,
        id: this.isEditMode ? this.selectedRowData._id : null,
        companyId,
        question: {
          value: this.transformOriginalQuestionValue(
            this.formDataForm.value?.question,
          ),
        },
      };

      this.formDataService.createOrUpdateFormData(formData).subscribe({
        next: (res) => {
          if (res?.success) {
            this.handleSuccess(
              ERROR_MESSAGES.SUCCESS_CREATE_UPDATE(this.isEditMode),
            );
          } else {
            this.isSubmitting = false;
            if (res?.message?.includes('already exists')) {
              this.messageHandlerService.generateToastMessage(
                'error',
                ERROR_MESSAGES.DUPLICATE_QUESTION_HEADER,
                res?.message,
              );
            }
            throw new Error(
              res?.errorMessage ||
                ERROR_MESSAGES.CREATE_UPDATE_FORM(this.isEditMode),
            );
          }
          this.isSubmitting = false;
        },
        error: (error) => {
          this.isSubmitting = false;
          this.handleError(
            error,
            ERROR_MESSAGES.CREATE_UPDATE_FORM(this.isEditMode),
          );
        },
      });
    }
  }

  // When editing existing form data
  patchFormData(rowData: FormDataObject) {
    try {
      if (!rowData) {
        throw new Error(ERROR_MESSAGES.NO_ROW_DATA);
      }
      const companyData = this.companyOptions.find(
        (company) => company.value === rowData.companyId,
      );
      if (
        !companyData &&
        (this.currentUser.is_super_admin || this.currentUser.is_IOH_admin)
      ) {
        throw new Error(ERROR_MESSAGES.COMPANY_NOT_FOUND);
      }

      let agencyIds = rowData.agencies?.map((agency: any) => agency._id);

      if (!this.isSuperAdminOrCompanyAdmin) {
        agencyIds = agencyIds.filter((agencyId) =>
          this.userAgencyIds.includes(agencyId),
        );
      }
      this.questionSelectedOption = rowData.questionOption;
      this.formDataForm.patchValue({
        companyId: companyData,
        agencies: agencyIds,
        visitTypes: rowData.visitTypes,
        question: rowData.question?.value,
        questionOption: rowData.questionOption,
        subjectiveOption: rowData.subjectiveOption,
      });
      // Clear existing form list
      while (this.formList.length) {
        this.formList.removeAt(0);
      }

      if (!rowData.formList || !Array.isArray(rowData.formList)) {
        throw new Error(ERROR_MESSAGES.INVALID_FORM_LIST);
      }

      rowData.formList.forEach((item) => {
        if (!item.title || !item.body) {
          throw new Error(ERROR_MESSAGES.INVALID_FORM_ITEM);
        }
        this.formList.push(
          this.formBuilder.group({
            title: [item.title, Validators.required],
            body: [item.body, Validators.required],
          }),
        );
      });
    } catch (error) {
      this.handleError(error, ERROR_MESSAGES.FORM_DATA_LOAD);
      this.onSlideBarClose();
    }
  }

  onRowClick(rowData: FormDataObject) {
    this.isEditMode = true;
    this.sideFormVisible = true;
    this.selectedRowData = rowData; // Add this property to your class
    this.getAgencyListing(rowData.companyId);
    this.patchFormData(rowData);
  }

  getAgencyListing(companyId: string) {
    const params = new HttpParams().set('companyId', companyId);
    this.subscriptions.push(this.loadAgencies(params));
  }

  showCompanyDropdown(): boolean {
    return this.currentUser?.is_super_admin || this.currentUser?.is_IOH_admin;
  }

  // Add these private methods for common functionality
  private handleError(
    error: any,
    customMessage: string = ERROR_MESSAGES.COMMUNICATION,
  ) {
    this.loading = false;
    this.progressBarService.setProgressBarVisibility(false);
    this.messageHandlerService.generateToastMessage(
      'error',
      customMessage,
      error?.errorMessage || error?.message || error,
    );
  }

  private handleSuccess(message: string) {
    this.messageHandlerService.generateToastMessage('success', '', message);
    this.getFormDataList();
    this.onSlideBarClose();
  }

  get maskedQuestion() {
    const value = this.questionTextPipe.transform(
      this.formDataForm.get('question')?.value,
    );

    // Preserve cursor position
    this.preserveQuestionFieldCursorPosition(value);
    return value;
  }

  private preserveQuestionFieldCursorPosition(value: string) {
    const textarea = document.querySelector(
      'textarea[formControlName="question"]',
    ) as HTMLTextAreaElement;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // Update the value while preserving cursor position
      const displayValue = this.questionTextPipe.transform(value);
      textarea.value = displayValue;

      // Restore cursor position
      textarea.setSelectionRange(start, end);
    }
  }

  onDeleteClick(event: Event, rowData: FormDataObject) {
    event.stopPropagation();

    this.confirmationService.confirm({
      message: ERROR_MESSAGES.CONFIRM_QUESTION_DELETE.MESSAGE,
      header: ERROR_MESSAGES.CONFIRM_QUESTION_DELETE.HEADER,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: ERROR_MESSAGES.CONFIRM_QUESTION_DELETE.ACCEPT,
      rejectLabel: ERROR_MESSAGES.CONFIRM_QUESTION_DELETE.REJECT,
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
        this.deleteFormData(rowData);
      },
    });
  }

  private deleteFormData(rowData: FormDataObject) {
    // Set loading state for this specific row
    const index = this.formDataList.findIndex(
      (item) => item._id === rowData._id,
    );
    if (index !== -1) {
      this.formDataList[index] = {
        ...this.formDataList[index],
        isDeleting: true,
      };
    }

    this.formDataService.deleteFormData({ id: rowData._id }).subscribe({
      next: (res) => {
        if (res?.success) {
          this.messageHandlerService.generateToastMessage(
            'success',
            '',
            ERROR_MESSAGES.QUESTION_DELETE_SUCCESS,
          );
          this.getFormDataList();
        } else {
          throw new Error(
            res?.errorMessage || ERROR_MESSAGES.QUESTION_DELETE_FAILED,
          );
        }
      },
      error: (error) => {
        // Reset loading state on error
        if (index !== -1) {
          this.formDataList[index] = {
            ...this.formDataList[index],
            isDeleting: false,
          };
        }
        this.handleError(error, ERROR_MESSAGES.QUESTION_DELETE_FAILED);
      },
    });
  }

  onQuestionOptionChange(event) {
    try {
      const questionSelectedOption = event.value; //this.formDataForm.get('questionOption')?.value;
      if (questionSelectedOption === this.subjectiveDefaultValue) {
        this.questionSelectedOption = questionSelectedOption;
        this.formDataForm.get('question')?.setValue('');
        this.formDataForm.get('subjectiveOption');
      } else {
        this.questionSelectedOption = '';
        this.formDataForm
          .get('question')
          ?.setValue(this.questionOptionsValues[questionSelectedOption]);
        this.formDataForm.get('subjectiveOption');
      }

      // reset variant
      this.formDataForm.get('subjectiveOption')?.setValue('');
    } catch (error) {
      this.handleError(error, ERROR_MESSAGES.FORM_DATA_LOAD);
    }
  }

  onSubjectiveChange() {
    try {
      const subjectiveSelectedOption =
        this.formDataForm.get('subjectiveOption')?.value;
      this.formDataForm
        .get('question')
        ?.setValue(this.questionOptionsValues[subjectiveSelectedOption]);
    } catch (error) {
      this.handleError(error, ERROR_MESSAGES.FORM_DATA_LOAD);
    }
  }

  onQuestionChange(value: any) {
    if (!value) return;
    const originalValue = this.transformOriginalQuestionValue(value);

    let found = false;
    Object.keys(this.questionOptionsValues).forEach((key) => {
      if (
        this.questionOptionsValues[key] === value ||
        this.questionOptionsValues[key] === originalValue
      ) {
        // TODO: replace hardcoded values with constants
        if (key === SUBJECTIVE_X11_DEFAULT_VALUE || key === SUBJECTIVE_OTHER_DEFAULT_VALUE) {
          this.questionSelectedOption = SUBJECTIVE_DEFAULT_VALUE;
          this.formDataForm.patchValue({
            questionOption: SUBJECTIVE_DEFAULT_VALUE,
            subjectiveOption: key,
          });
          found = true;
        } else {
          this.questionSelectedOption = key;
          this.formDataForm.patchValue({
            questionOption: key,
            subjectiveOption: '',
          });
          found = true;
        }
      }
    });

    if (!found) {
      this.questionSelectedOption = '';
      this.formDataForm.patchValue({
        questionOption: '',
        subjectiveOption: '',
      });
    }
  }
}
