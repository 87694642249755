interface FormList {
  title: string;
  body: string;
}
export interface FormDataObject {
  _id: string;
  version?: string;
  companyId: string;
  visitTypes: string;
  question: { value: string };
  formList: FormList[];
  agencies: string[];
  isDeleting?: boolean;
  questionId: string;
  subjectiveOption?: any;
  questionOption?: any;
}

export const visitTypeOptions = [
    { label: 'SOC', value: 'soc' },
    { label: 'Non-SOC', value: 'nonSoc' },
  ];

export const questionTypeOptions = [
  { value: 'assessment', label: 'Assessment' },
  { value: 'plan', label: 'Plan' },
  { value: 'subjective', label: 'Subjective' },
  ];

export const subjectiveOptions = [
  { value: 'xx11', label: 'XX11' },
  { value: 'others', label: 'Others' }
  ];

export const questionOptionsValues = {
  assessment: "INDICATE__THERAPY__ASSESSMENT,__INCLUDING__THE__MAIN__IMPAIRMENTS__TO__ADDRESS__BASED__ON__EVALUATION__FINDINGS__AND__WHY__SKILLED__THERAPY__IS__NEEDED__BASED__ON__DECLARED__REHAB__POTENTIAL:",
  plan: "INDICATE__THERAPY__PLAN,__INCLUDING__THE__MAIN__FOCUS__FOR__NEXT__VISIT'S__TREATMENT:",
  subjective: "subjective",
  xx11: "INDICATE__THERAPY__SUBJECTIVE:",
  others: "INDICATE__THERAPY__SUBJECTIVE,__INCLUDING__PLOF__AND__SUMMARY__OF__RELEVANT__HEALTH__HISTORY__AS__PROVIDED__BY__THE__PATIENT__OR__CAREGIVER:"
}

